<template>
    <div>
        <div class="col-lg-2 col-md-3 col-sm-3 nav_side tool-affix">
            <form>
                <div class="form-group">
                    <label>Start Date</label>
                    <DatePicker v-model="startDate" :input-attributes="{ class: 'form-control' }"></DatePicker>
                </div>
                <div class="form-group">
                    <label>End Date</label>
                    <DatePicker v-model="endDate" :input-attributes="{ class: 'form-control' }"></DatePicker>
                </div>
                <div class="form-group">
                    <label>CDI Location</label>
                    <select class="form-control" v-model="cdiLocationID">
                        <option v-for="[cdiLocationID, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="cdiLocationID" :value="cdiLocationID">{{cdiLocation}}</option>
                    </select>
                </div>

                <div class="form-group">
                    <a @click="load" class="pointer">Filter</a>
                </div>
                <div class="form-group">
                    <a @click="create" class="pointer">Consolidate</a>
                </div>
            </form>
        </div>
        <div class="main col-lg-10 col-md-9 col-sm-9 left_gutter main fill tool-affix-main">
            <div>
                <h1>Shipping Consolidation</h1>
                <div class="table-responsive">
                    <form>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Co</th>
                                    <th>Order</th>
                                    <th>Sch Ship</th>
                                    <th>Invoice Amt</th>
                                    <th>State</th>
                                    <th>Zip</th>
                                    <th>City</th>
                                    <th>Carrier</th>
                                    <th>Group#</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="row in this.results" v-bind:key="row.tracker_id">
                                    <td>
                                        <input type="checkbox"
                                               v-model="trackersToConsolidate"
                                               :value="row.tracker_id"
                                               @change="checkGroup(row.tracker_id, row.tracker_group_id)"
                                               :disabled="row.disabled ? row.disabled : false"
                                        />
                                    </td>
                                    <td>
                                        <router-link :to="`/tracker/${row.tracker_id}`">
                                            {{ row.tracker_id }}
                                        </router-link>
                                    </td>
                                    <td>{{ row.scheduled_ship }}</td>
                                    <td>{{ row.total_amount }}</td>
                                    <td>{{ row.shipto_state }}</td>
                                    <td>{{ row.shipto_zip }}</td>
                                    <td>{{ row.shipto_city }}</td>
                                    <td>{{ row.shipping_co }}</td>
                                    <td>{{ row.tracker_group_id }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Tool.store';
    import appStore from "@/store/App.store";
    import DatePicker from "@/components/utils/DatePicker";
    import DateHelpers from '@/store/DateHelpers.js';
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';

    export default {
        name: "ShipConsolidate",
        components: {DatePicker},
        data() {
            return {
                cache: {
                    cdiLocationCache
                },
                results: [],
                trackersToConsolidate: [],
                groupID: 0,
                startDate: DateHelpers.getToday(),
                endDate: DateHelpers.getToday(),
                cdiLocationID: 1
            }
        },
        created() {
            this.load();
        },
        methods: {
            load: function () {
                let options = {
                    'params[dates][scheduled_ship_from]': this.startDate,
                    'params[dates][scheduled_ship_to]': this.endDate,
                    'params[numbers][cdi_location_id]': this.cdiLocationID,
                };

                store.reportsApi('/trackers/consolidation/', options).then(results => this.results = results);
                this.trackersToConsolidate = [];
                this.groupID = 0;
            },
            checkGroup: function (trackerID, groupID) {
                let resetDisable = false;

                if (this.trackersToConsolidate.length === 0) {
                    resetDisable = true;
                    this.groupID = 0;
                } else if (this.trackersToConsolidate.length > 0 && groupID > 0 && !this.anyLeftCheckedInGroup(groupID)) {
                    resetDisable = true;
                    this.groupID = 0;
                } else if (groupID > 0) {
                    this.groupID = groupID;
                }

                let counter = -1;
                this.results.forEach(row => {
                    counter++;

                    if (resetDisable) {
                        this.results[counter].disabled = false;
                    } else if (this.groupID === 0) {
                        return;
                    }

                    // Skip iteration for tracker that was clicked
                    if (row.tracker_id === trackerID) {
                        return;
                    }

                    // If the order checked belonged to a group
                    if (groupID) {
                        // Add other trackers of that group to be checked if a checkbox was just checked
                        if (row.tracker_group_id === this.groupID && !this.trackersToConsolidate.includes(row.tracker_id) && this.trackersToConsolidate.includes(trackerID)) {
                            this.trackersToConsolidate.push(row.tracker_id);
                        }
                    }

                    if (parseInt(row.tracker_group_id) > 0 && this.groupID > 0 && row.tracker_group_id !== this.groupID) {
                        this.results[counter].disabled = true;
                    }
                });
            },
            anyLeftCheckedInGroup: function (groupID) {
                for (let i = 0; i < this.results.length; i++) {
                    if (!this.trackersToConsolidate.includes(this.results[i].tracker_id)) {
                        continue;
                    }

                    let rowGroupID = this.results[i].tracker_group_id;

                    if (rowGroupID > 0 && rowGroupID === groupID) {
                        return true;
                    }
                }

                return false;
            },
            create: function () {
                let options = {
                    'params[tracker_group_id]': this.groupID,
                    'params': this.trackersToConsolidate
                };

                store.reportsApi('/trackers/create_tracker_group/', options).then(result => {
                    if (result === false) {
                        appStore.errorBox('Error trying to create consolidation')
                    }

                    this.load();
                });
            }
        }
    }
</script>

<style scoped>
.sidebar{
    top: 15%;
    position: sticky!important;
    font-size: 1.2em;
    z-index:3333!important;
}
label{
    font-weight: 700;
}
@media screen and (max-width: 850px) {
    .sidebar{
        top:0px;
        position: relative!important;
       z-index: 1!important;
    }
}

</style>